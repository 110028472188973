import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import * as styles from './Photo.module.scss'
import { PhotoInterface, PhotoSetInterface, PhotosInterface } from '../../interfaces/PhotoInterface'
import isPrerender from '../../utils/isPrerender'

export const IMAGE_NOT_FOUND = '/img/not_found.jpg'
type PhotoSetType = 'product' | 'catalog' | 'thumb' | 'custom' | 'landing'
type PhotoTypeType = 'product' | 'catalog'

type DefaultProps = {
  set: PhotoSetType
  type: PhotoTypeType
}

type Props = Partial<DefaultProps> & {
  alt?: string
  classes?: string
  src: string | PhotosInterface | PhotoSetInterface
  secondSrc?: string | PhotosInterface | PhotoSetInterface
  width?: number
  height?: number
  noEmpty?: true
  minHeight?: number
}

const defaultProps: DefaultProps = {
  set: 'product',
  type: 'product',
}

function sortPosition(imageA: PhotoInterface, imageB: PhotoInterface) {
  return imageA.position - imageB.position
}

const getSourcePath = (
  src: string | PhotosInterface | PhotoSetInterface,
  second: boolean,
  type: PhotoTypeType,
  set: PhotoSetType,
  query?: string,
) => {
  let path = ''
  if (typeof src === 'string') {
    path = `${src}${query}`
  } else if (set && _.has(src, set) && _.isArray(src[set])) {
    src = src as PhotosInterface
    const photosTyped = src[set].filter(item => item[type])

    let foundPhotoIdx = photosTyped.sort(sortPosition).findIndex(item => {
      return item[type] && item[type] === true
    })

    if (second) {
      foundPhotoIdx = photosTyped[foundPhotoIdx + 1] ? foundPhotoIdx + 1 : foundPhotoIdx
    }
    if (second && foundPhotoIdx === 0) {
      return false
    }
    path = `${src[set][foundPhotoIdx]?.url}${query}` || IMAGE_NOT_FOUND
  } else if (set && _.has(src, set) && _.isObject(src[set])) {
    path = `${(src as PhotoSetInterface)[set]?.url}${query}` || IMAGE_NOT_FOUND
  }

  return path
}

const Photo = (props: Props) => {
  const { classes, alt, width, height, src, noEmpty, type, secondSrc, set, minHeight } = { ...defaultProps, ...props }
  const [activeSec, setActiveSec] = React.useState<boolean>(false)
  const fixedSet = type === 'catalog' ? 'catalog' : set
  const [srcPath, setSrcPath] = useState<string>('')
  const [srcSecondPath, setSrcSecondPath] = useState<string | false>('')
  const [loaded, setLoaded] = useState<boolean>(false)
  const [secondLoaded, setSecondLoaded] = useState<boolean>(false)

  useDeepCompareEffect(() => {
    const query = width! > 0 || height! > 0 ? '?' + (width! > 0 ? `w=${width}` : '') + (height! > 0 ? `w=${height}` : '') : ''
    setSecondLoaded(false)
    setLoaded(false)
    setSrcPath(getSourcePath(src, false, type, fixedSet, query) || '')
    setSrcSecondPath(secondSrc ? getSourcePath(secondSrc, true, type, fixedSet, query) : false)
  }, [src, secondSrc])

  const onMouseEnter = () => {
    if (typeof srcSecondPath === 'string') {
      setActiveSec(true)
    }
  }

  const onMouseOut = () => {
    setActiveSec(false)
  }

  const onImageError = () => {
    setSrcPath(isPrerender() ? srcPath : IMAGE_NOT_FOUND)
    setLoaded(true)
  }

  const onSecondImageError = () => {
    setSrcSecondPath(isPrerender() ? srcSecondPath : IMAGE_NOT_FOUND)
    setSecondLoaded(true)
  }

  const imgsContent = (
    <>
      {!srcPath && noEmpty ? null : (
        <img
          width="100%"
          height="100%"
          className={classNames(classes, styles['img'], {
            [styles['img--hidden']]: activeSec,
            [styles['img--abs']]: !!srcSecondPath,
          })}
          style={{ minHeight }}
          src={srcPath}
          alt={props.alt}
          onMouseEnter={onMouseEnter}
          onLoad={() => {
            setLoaded(true)
          }}
          onError={onImageError}
        />
      )}
      {srcSecondPath ? (
        <img
          width="100%"
          height="100%"
          className={classNames(classes, styles['img'], {
            [styles['img--hidden']]: !activeSec,
            [styles['img--abs']]: !!srcSecondPath,
          })}
          style={{ minHeight }}
          src={srcSecondPath}
          alt={props.alt}
          onMouseLeave={onMouseOut}
          onLoad={() => setSecondLoaded(true)}
          onError={onSecondImageError}
        />
      ) : null}
    </>
  )
  const a = 1

  return (
    <>
      {!loaded && !activeSec ? (
        <Skeleton
          classes={{
            root: classNames(classes, styles['img'], styles['img--skeleton']),
          }}
          variant="rectangular"
          animation="pulse"
        />
      ) : null}

      {props.secondSrc ? <div className={styles['rollover']}>{imgsContent}</div> : imgsContent}
    </>
  )
}

export default Photo
