import { ACTION_METHOD } from './cart.actions'
import CartInterface, { ORDER_RETURN_TYPE } from '../../interfaces/CartInterface'
import axios from '../../utils/axios'
//TODO: zmienić naming z cartRead na readCart etc - zaktualziować dokumentacje
export function readCart() {
  return axios({
    method: 'GET',
    url: '/cart.json',
  })
}
/**
 * @param cart
 * @param strict force backend strict validation
 * @returns void
 */
export function updateCart(cart: CartInterface, strict: boolean = false) {
  const params = { strict: Number(strict) }
  if (cart.billingAddress === null) {
    //not nice  for types, but required for address issues WF-3413
    delete (cart as any).billingAddress
  }
  return axios({
    method: 'PUT',
    url: '/cart.json',
    data: cart,
    params,
  })
}

export function createCartProduct(productId: number, quantity: number = 1) {
  return axios({
    method: 'POST',
    url: '/cart/add.json',
    data: {
      action: ACTION_METHOD.ACTION_SET,
      productId,
      quantity,
    },
  })
}

export function updateCartProduct(productId: number, quantity: number = 1, action: ACTION_METHOD = ACTION_METHOD.ACTION_SET) {
  return axios({
    method: 'POST',
    url: '/cart/add.json',
    data: {
      action,
      productId,
      quantity,
    },
  })
}

export function readPaymentLink(paymentType: string, paymentId: number, values: object = {}) {
  return axios({
    data: values,
    method: 'POST',
    url: `/payment/${paymentType}/${paymentId}.json`,
  })
}

export function readPaymentEwayPrevalidate(paymentId: number) {
  return axios({
    method: 'GET',
    url: `/payment/eway/pay-now/check/${paymentId}.json`,
  })
}

export function readPaymentEwayValidate(paymentId: number, accessCode: string) {
  return axios({
    method: 'GET',
    url: `/payment/eway/pay-now/${paymentId}.json?AccessCode=${accessCode}`,
  })
}

export function readOrderByHash(encodedOrderId: string) {
  return axios({
    method: 'GET',
    url: `/payment/after/${encodedOrderId}.json`,
  })
}

export function createCartVoucher(code: string) {
  return axios({
    method: 'POST',
    url: '/cart/voucher.json',
    data: {
      code,
    },
  })
}

export function deleteCartVoucher(id: number) {
  return axios({
    method: 'DELETE',
    url: `/cart/voucher/${id}.json`,
  })
}

export function createCartLoyaltyPoints(points: number) {
  return axios({
    method: 'POST',
    url: '/cart/points.json',
    data: {
      points,
    },
  })
}

export function createGiftVoucher(
  giftId: number,
  senderName: string,
  senderEmail: string,
  recipientName: string,
  recipientEmail: string,
  amountGross: number,
  headline: string,
  message: string,
  isSendToRecipient: boolean,
  sendingDate: number | null,
) {
  return axios({
    method: 'POST',
    url: '/cart/gift.json',
    data: {
      giftId,
      senderName,
      senderEmail,
      recipientName,
      recipientEmail,
      amountGross,
      headline,
      message,
      isSendToRecipient,
      sendingDate,
    },
  })
}

export function deleteGiftVoucher(id: number) {
  return axios({
    method: 'DELETE',
    url: `/cart/gift/${id}.json`,
  })
}

export function createCartRestore(token: string) {
  return axios({
    method: 'POST',
    url: '/cart/abandoned.json',
    data: {
      token,
    },
  })
}

export function readShippingCarriers() {
  return axios({
    method: 'GET',
    url: '/cart/shipping/available.json',
  })
}

export function updateShippingCarrier(id: number) {
  return axios({
    method: 'POST',
    url: '/cart/shipping.json',
    data: {
      id,
    },
  })
}

export function selectShippingCountry(country: string) {
  return axios({
    method: 'POST',
    url: '/cart/country.json',
    data: {
      country,
    },
  })
}

export function readPaymentMethods() {
  return axios({
    method: 'GET',
    url: '/payment.json',
  })
}

export function readOrderById(id: number) {
  return axios({
    method: 'GET',
    url: `/order/${id}.json`,
  })
}

export function readOrderByToken(token: string) {
  return axios({
    method: 'GET',
    url: `/order/token.json?token=${token}`,
  })
}

export function readOrderByReturnIdAndToken(returnOrderId: number, token: string) {
  return axios({
    method: 'GET',
    url: `/order/return/${returnOrderId}.json?token=${token}`,
  })
}

export function readReturnEstimate(orderId: number, items: object[], returnType: ORDER_RETURN_TYPE, token?: string) {
  return axios({
    method: 'POST',
    url: '/order/return/estimate.json',
    data: {
      orderId: token ? undefined : orderId,
      flowType: returnType,
      items,
      token: token || '',
    },
  })
}

export function createReturn(orderId: number, items: object[], returnType: ORDER_RETURN_TYPE, token?: string) {
  return axios({
    method: 'POST',
    url: '/order/return.json',
    data: {
      orderId: token ? undefined : orderId,
      flowType: returnType,
      items,
      token: token || '',
    },
  })
}

export function readReturnInstruction(orderId: number) {
  return axios({
    method: 'GET',
    url: `/order/${orderId}/return_instructions.json`,
  })
}

export function requestReturn(email: string) {
  return axios({
    method: 'POST',
    url: '/order/available_returns.json',
    data: {
      email,
    },
  })
}
