import { useContext, useMemo } from 'react'
import { CHANNEL_PARAMS, ChannelConfig, PAYMENT_METHOD_LOGO_TYPE } from './channelConfig.types'
import ChannelContext from '../../context/ChannelContext'
import ChannelInterface from '../../interfaces/ChannelInterface'

const useChannelConfig = () => {
  const { current, alternate, main, opposite } = useContext(ChannelContext)

  const getParamValue = (channel: ChannelInterface, womenParam: CHANNEL_PARAMS, menParam: CHANNEL_PARAMS): string => {
    return channel.params.find(p => p.name === (channel.isWomenShop ? womenParam : menParam))?.value || ''
  }

  const getConfigForChannel = (channel: ChannelInterface): ChannelConfig => {
    const paymentMethodLogoType = getParamValue(
      channel,
      CHANNEL_PARAMS.FUNKITA_PAYMENT_METHOD_LOGO_TYPE,
      CHANNEL_PARAMS.FUNKYTRUNKS_PAYMENT_METHOD_LOGO_TYPE,
    )
    return {
      continueShoppingUrl: getParamValue(
        channel,
        CHANNEL_PARAMS.FUNKITA_CONTINUE_SHOPPING_URL,
        CHANNEL_PARAMS.FUNKYTRUNKS_CONTINUE_SHOPPING_URL,
      ),
      swimwearUrl: getParamValue(channel, CHANNEL_PARAMS.FUNKITA_SWIMWEAR_URL, CHANNEL_PARAMS.FUNKYTRUNKS_SWIMWEAR_URL),
      gearUrl: getParamValue(channel, CHANNEL_PARAMS.FUNKITA_GEAR_URL, CHANNEL_PARAMS.FUNKYTRUNKS_GEAR_URL),
      outletUrl: getParamValue(channel, CHANNEL_PARAMS.FUNKITA_OUTLET_URL, CHANNEL_PARAMS.FUNKYTRUNKS_OUTLET_URL),
      koreaOrderValue: getParamValue(channel, CHANNEL_PARAMS.FUNKITA_KOREA_ORDER_VALUE, CHANNEL_PARAMS.FUNKYTRUNKS_KOREA_ORDER_VALUE),
      headerTextLeft: getParamValue(channel, CHANNEL_PARAMS.FUNKITA_HEADER_TEXT_LEFT, CHANNEL_PARAMS.FUNKYTRUNKS_HEADER_TEXT_LEFT),
      headerTextRight: getParamValue(channel, CHANNEL_PARAMS.FUNKITA_HEADER_TEXT_RIGHT, CHANNEL_PARAMS.FUNKYTRUNKS_HEADER_TEXT_RIGHT),
      allowForcedShippingCountry:
        getParamValue(
          channel,
          CHANNEL_PARAMS.FUNKITA_ALLOW_FORCED_SHIPPING_COUNTRY,
          CHANNEL_PARAMS.FUNKYTRUNKS_ALLOW_FORCED_SHIPPING_COUNTRY,
        ) === '1',
      consentBoxPopup:
        getParamValue(channel, CHANNEL_PARAMS.FUNKITA_CONSENT_BOX_POPUP, CHANNEL_PARAMS.FUNKYTRUNKS_CONSENT_BOX_POPUP) === '1',
      usOrderValue: getParamValue(channel, CHANNEL_PARAMS.FUNKITA_US_ORDER_VALUE, CHANNEL_PARAMS.FUNKYTRUNKS_US_ORDER_VALUE),
      paymentMethodLogoType: paymentMethodLogoType ? parseInt(paymentMethodLogoType, 10) : PAYMENT_METHOD_LOGO_TYPE.NONE,
    }
  }

  const currentChannelConfig = useMemo(() => getConfigForChannel(current), [current.id])
  const alternateChannelConfig = useMemo(() => (alternate ? getConfigForChannel(alternate) : null), [alternate?.id])
  const mainChannelConfig = useMemo(() => getConfigForChannel(main), [main.id])
  const oppositeChannelConfig = useMemo(() => (opposite ? getConfigForChannel(opposite) : null), [opposite?.id])

  return {
    current: currentChannelConfig,
    alternate: alternateChannelConfig,
    main: mainChannelConfig,
    opposite: oppositeChannelConfig,
  }
}

export default useChannelConfig
