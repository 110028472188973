import React from 'react'
import ChildrenType from '../../../interfaces/ChildrenInterface'
import Link from '../../Link'

type AriaCurrent = 'page' | 'step' | 'location' | 'date' | 'time' | 'true'

type DefaultProps = {
  ariaCurrent: AriaCurrent
}

type Classes = {
  root?: string
}

type Props = Partial<DefaultProps> & {
  classes?: Classes
  style?: React.CSSProperties
  to: string | object
  onClick?: () => void
  children: ChildrenType
}

const defaultProps: DefaultProps = {
  ariaCurrent: 'page',
}

const NavigationLink = (_props: Props) => {
  const props = { ...defaultProps, ..._props }
  const { classes, to, onClick, ariaCurrent, style, children } = props
  return (
    <Link aria-current={ariaCurrent} className={classes?.root} style={style} to={to} onClick={onClick}>
      {children}
    </Link>
  )
}

export default NavigationLink
