import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import InstagramIcon from '@mui/icons-material/Instagram'
import PinterestIcon from '@mui/icons-material/Pinterest'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { ClickAwayListener, Tooltip } from '@mui/material'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import SocialIcon from './SocialIcon'
import styles from './SocialIcons.module.scss'
import ChannelContext from '../../context/ChannelContext'

type SocialItemType = {
  url: string
  icon: React.ReactNode
}

type SocialItemsType = {
  [theme: string]: {
    [name: string]: SocialItemType
  }
}

type DefaultProps = {
  inversed: boolean
}

type Props = Partial<DefaultProps> & {
  classes?: {
    root?: string
    icon?: string
  }
  shareMode?: boolean
  shareUrl?: string
  shareImageUrl?: string
}

const defaultProps: DefaultProps = {
  inversed: false,
}

const SocialIcons = (props: Props) => {
  const { inversed, classes, shareMode, shareUrl, shareImageUrl } = { ...defaultProps, ...props }
  const channel = useContext(ChannelContext)
  const [copiedTooltipOpen, setCopiedTooltipOpen] = React.useState(false)

  const theme = channel.current.theme
  const iconClass = { root: classNames(styles['item__icon'], { [styles['item__icon--inversed']]: inversed }, classes?.icon) }

  const fbFK = channel.current.params.find(i => i.name == 'funkitaFacebookUrl')?.value || ''
  const ptFK = channel.current.params.find(i => i.name == 'funkitaPinterestUrl')?.value || ''
  const ttFK = channel.current.params.find(i => i.name == 'funkitaTwitterUrl')?.value || ''
  const inFK = channel.current.params.find(i => i.name == 'funkitaInstagramUrl')?.value || ''
  const ytFK = channel.current.params.find(i => i.name == 'funkitaYoutubeUrl')?.value || ''

  const fbFT = channel.current.params.find(i => i.name == 'funkytrunksFacebookUrl')?.value || ''
  const ptFT = channel.current.params.find(i => i.name == 'funkytrunksPinterestUrl')?.value || ''
  const ttFT = channel.current.params.find(i => i.name == 'funkytrunksTwitterUrl')?.value || ''
  const inFT = channel.current.params.find(i => i.name == 'funkytrunksInstagramUrl')?.value || ''
  const ytFT = channel.current.params.find(i => i.name == 'funkytrunksYoutubeUrl')?.value || ''

  const socialLinks: SocialItemsType = {
    funkita: {
      facebook: { url: fbFK, icon: <FacebookIcon classes={iconClass} /> },
      pinterest: { url: ptFK, icon: <PinterestIcon classes={iconClass} /> },
      twitter: { url: ttFK, icon: <TwitterIcon classes={iconClass} /> },
      youtube: { url: ytFK, icon: <YouTubeIcon classes={iconClass} /> },
      instagram: { url: inFK, icon: <InstagramIcon classes={iconClass} /> },
    },
    funkytrunks: {
      facebook: { url: fbFT, icon: <FacebookIcon classes={iconClass} /> },
      pinterest: { url: ptFT, icon: <PinterestIcon classes={iconClass} /> },
      twitter: { url: ttFT, icon: <TwitterIcon classes={iconClass} /> },
      youtube: { url: ytFT, icon: <YouTubeIcon classes={iconClass} /> },
      instagram: { url: inFT, icon: <InstagramIcon classes={iconClass} /> },
    },
  }

  if (shareMode) {
    return (
      <div className={classNames(styles['social'], classes?.root)}>
        <SocialIcon
          onClick={() => {
            navigator.clipboard.writeText(decodeURIComponent(shareUrl ?? ''))
            setCopiedTooltipOpen(true)
          }}
          icon={
            <ClickAwayListener
              onClickAway={() => {
                setCopiedTooltipOpen(false)
              }}
            >
              <Tooltip
                open={copiedTooltipOpen}
                title={<FormattedMessage id="App.Message.Copied" description="App.Message.Copied" defaultMessage="Copied" />}
                placement="top"
                arrow
              >
                <InsertLinkIcon classes={iconClass} />
              </Tooltip>
            </ClickAwayListener>
          }
        />

        <SocialIcon
          openInPopup
          url={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          icon={<FacebookIcon classes={iconClass} />}
        />
        <SocialIcon openInPopup url={`https://twitter.com/home?status=${shareUrl}`} icon={<TwitterIcon classes={iconClass} />} />
        <SocialIcon
          openInPopup
          url={`https://pinterest.com/pin/create/button/?url=${shareUrl}&media=${shareImageUrl}&description=`}
          icon={<PinterestIcon classes={iconClass} />}
        />
        <SocialIcon url={`mailto:?body=${shareUrl}`} icon={<EmailIcon classes={iconClass} />} />
      </div>
    )
  }

  return (
    <div className={classNames(styles['social'], classes?.root)}>
      <SocialIcon {...socialLinks[theme].facebook} />
      <SocialIcon {...socialLinks[theme].pinterest} />
      <SocialIcon {...socialLinks[theme].twitter} />
      <SocialIcon {...socialLinks[theme].youtube} />
      <SocialIcon {...socialLinks[theme].instagram} />
    </div>
  )
}

export default SocialIcons
